:root {
  --bg-color-primary: rgb(254, 243, 225);
  --bg-color-secondary: rgb(33, 31, 28);
  --typewriterspeed: 4s;
  --text-primary: #000;
  --text-secondary: #fff;
  --stagger-delay: calc(200ms);
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  font-family: 'Space Mono', sans-serif;
  /* display: grid; */
  place-items: center;
}

@media (prefers-reduced-motion: reduce) {
  *,
  ::before,
  ::after {
      animation-delay: -1ms !important;
      animation-duration: 1ms !important;
      animation-iteration-count: 1 !important;
      background-attachment: initial !important;
      scroll-behavior: auto !important;
      transition-duration: 0s !important;
      transition-delay: 0s !important;
  }
}
