.about-page {
    place-items: center;
    z-index: 1;
    width: 100%;
}

.card {
    right: 0;
    left: 0;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    max-width: 100%;
    display: flex;
    flex-direction: column;
    background: lightblue;
    color: white;
    text-align: center;
    padding: 2em;
}

.social-list {
    display: flex;
    justify-content: space-around;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 125px;
    margin: 0 auto;
}

.social-link {
    color: lightcoral;
    opacity: 0.5;
    font-size: 1.125rem;
}

.social-link:hover,
.social-link:focus {
    opacity: 1;
}

.profile-name {
    font-size: 2rem;
    line-height: 1.1;
    letter-spacing: 1px;
}

.profile-name::after {
    content: '';
    display: block;
    height: 1px;
    width: 4em;
    background: lightcoral;
    margin: 0.65em auto 0.5em;
    opacity: 0.35;
}

.profile-position {
    margin: 0;
    text-transform: uppercase;
    font-size: .875rem;
    letter-spacing: 3px;
    color: lightcoral;
    margin-bottom: 2em;
}

.profile-body {
    font-weight: 300;
    margin-bottom: 0;
}

.profile-image {
    height: 7.8125em;
    border-radius: 50%;
    border: 2px solid white;
}

@media (min-width: 600px) {
    .card {
        flex-direction: row;
        max-width: 100%;
    }

    .profile-main {
        text-align: left;
        margin-left: 2em;
    }

    .profile-name::after {
        margin-left: 0;
    }
}