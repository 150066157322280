.projects-page {
    background: var(--bg-color-secondary);
    color: var(--text-primary);
    min-height: 100vh;
    display: grid;
    place-items: center;
    box-shadow: inset 0 0 20px rgba(0, 0, 0, 0.2);
}

.projects-wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    place-content: center;
}

.projects-page h1{
    top: 0;
    left: 0;
    color: white;
}

.project-card {
    color: white;
    background-size: cover;
    max-width: 32.6ch;
    border-radius: 0.5rem;
    padding: 10rem 0 0;
    overflow: hidden;
    transition: transform 500ms ease;
    margin: 1rem;

    /* left: 0;
    right: 0; */

    box-shadow: 0.2em 0.4em 0.3em 0.125px rgb(0 0 0 / 80%);
}

.project-card:hover,
.project-card:focus-within {
    transform: scale(1.05);
    box-shadow: 0.2em 0.6em 0.4em 0.125px rgb(0 0 0 / 80%);
}

.project-card-content {
    --padding: 1.5rem;
    height: 14rem;
    padding: var(--padding);
    background: linear-gradient(
        hsl(0 0% 0% / 0),
        hsl(20 0% 0% / 0.3) 20%,
        hsl(0 0% 0% / 1)
    );
}

.project-card-title {
    position: relative;
    width: max-content;
    max-width: 100%;
}

.project-card-title::after {
    content: '';
    position: absolute;
    height: 4px;
    left: calc(var(--padding) * -1);
    bottom: -2px;
    width: calc(100% + var(--padding));
    background: mediumaquamarine;
    transform-origin: left;
    transition: transform 500ms ease;
}

.project-card:hover .project-card-title::after,
.project-card:focus-within .project-card-title::after {
    transform: scaleX(1);
}

.project-card-body {
    color: rgba(255, 255, 255, 0.85);
}

.project-button {
    cursor: pointer;
    display: inline-block;
    text-decoration: none;
    color: white;
    background-color: mediumaquamarine;
    padding: 0.5em 1.25em;
    border-radius: 0.25em;
}

.project-button:hover,
.project-button:focus {
    background-color: mediumturquoise;
}

@media (hover) {
    .project-card-content {
        transform: translateY(65%);
        transition: transform 500ms ease;
    }

    .project-card:hover .project-card-content,
    .project-card:focus-within .project-card-content {
        transform: translateY(0);
        transition-delay: 500ms;
    }

    .project-card:focus-within .project-card-content {
        transition-duration: 0ms;
    }

    .project-card-content > *:not(.project-card-title) {
        opacity: 0;
        transition: opacity 500ms linear;
    }

    .project-card:hover .project-card-content > *:not(.project-card-title){
        opacity: 1;
        transition-delay: 800ms;
    }

    .project-card:focus-within .project-card-content > *:not(.project-card-title) {
        opacity: 1;
        transition-delay: 200ms;
    }

    .project-card-title::after {
        transform: scaleX(0);
    }
}

@media (min-width: 600px) {
    .projects-wrapper {
        flex-direction: row;
        margin: 0 1rem;
    }
    .project-card {
        max-height: 35ch;
    }
    .project-button {
        bottom:   0;
        margin-bottom: 1rem;
    }
}