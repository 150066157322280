nav {
    position: fixed;
    text-align: right;
    padding-right: 0.5em;
    right: 0;
    top: 0;
    z-index: 1;
}

.mobile-nav {
    color: var(--text-primary);
    margin-right: 1rem;
}

.nav-close {
    display: none;
}

nav ul {
    list-style-type: none;
    padding: 0;
}

nav a {
    text-decoration: none;
    color: inherit;
}

nav li {
    display: block;
    padding: 0.5em 1.5em;
    cursor: pointer;
    margin-right: 0.1em;
    border-left: 0.125em rgb(57, 57, 57) solid;
}

nav li:hover,
nav li:focus {
    background-color: rgb(235, 227, 216);
}

nav li:nth-child(1) {
    animation-delay: calc(1 * var(--stagger-delay) + var(--typewriterspeed));
    border-style: none;
}
nav li:nth-child(2) {
    animation-delay: calc(2 * var(--stagger-delay) + var(--typewriterspeed));
}
nav li:nth-child(3) {
    animation-delay: calc(3 * var(--stagger-delay) + var(--typewriterspeed));
}
nav li:nth-child(4) {
    animation-delay: calc(4 * var(--stagger-delay) + var(--typewriterspeed));
}

@keyframes fadeInDown {
    to {
        transform: translateY(0);
    }
}

/* On Scroll */
.nav-scrolled {
    width: 100%;
    background-color: var(--bg-color-secondary);
    border-bottom: 0.125em var(--bg-color-primary) solid;
    box-shadow: 0 0.1875em 1.25em rgb(0, 0, 0, 0.2);
    opacity: 0;
    animation: fadeIn 500ms ease forwards;
}

.nav-scrolled ul li {
    /* border-left: 0.125em white solid; */
    border-left: 0.125em var(--bg-color-secondary) solid;
    color: var(--text-secondary);
    text-decoration: none;
}

.nav-scrolled ul li a {
    color: var(--bg-color-primary);
}

.nav-scrolled ul li:hover {
    background-color: transparent;
    border-left: 0.125em var(--bg-color-primary) solid;
}

.nav-scrolled ul li:hover a {
    color: rgb(218, 194, 157);
}

.nav-scrolled .mobile-nav{
    color: white;
}

@keyframes fadeIn {
    to {
        opacity: 1;
    }
}

@media (min-width: 600px) {
    nav li {
        display: inline-block;
        transform: translateY(-5rem);
        animation: fadeInDown 2s ease forwards;
    }
    .mobile-nav {
        display: none;
    }
    .nav-close {
        display: block;
    }
}