/* Stage 1 Transition In */
.home-page {
    background: var(--bg-color-primary);
    min-height: 100vh;
    display: grid;
    place-content: center;
    text-align: center;
}

.home-page h1 {
    margin: 0.5em;
}

.typewriter {
    font-size: clamp(1rem, 3vw + 1rem, 4rem);
    position: relative;
    font-family: 'Space Mono', monospace;
    /* width: max-content; */
    width: fit-content;
}

.typewriter::before,
.typewriter::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.typewriter::before {
    background: var(--bg-color-primary);
    animation: typewriter var(--typewriterspeed) steps(22) 1s forwards;
}

.typewriter::after {
    width: .100em;
    background: black;
    animation: typewriter var(--typewriterspeed) steps(22) 1s forwards,
    blink 750ms steps(22) infinite;
}

.subtitle {
    color: hsl(0 0% 0% / 0.7);
    font-size: 2rem;
    font-weight: 400;
    opacity: 0;
    transform: translateY(3rem);
    animation: fadeInUp 2s ease calc(var(--typewriterspeed) + 0.5s) forwards;
}

@keyframes typewriter {
    to {
        left: 100%;
    }
}

@keyframes blink {
    to {
        background: transparent;
    }
}

@keyframes fadeInUp {
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Stage 2 Glitch */
/* .glitch {
    text-shadow: 
        0.05em 0 0 rgba(255, 0, 0, .75),
        -0.025em -0.05em 0 rgba(0, 255, 0, .75),
        0.025em 0.05em 0 rgba(0, 0, 255, .75);
    animation: glitch 500ms infinite;
    animation-delay: 10s;
}

.glitch span {
    position: absolute;
    top: 0;
    left: 0;
    animation: glitchVis 1s;
    animation-delay: 10s;
}

.glitch span:first-child {
    animation: glitch 650ms infinite;
    animation-delay: 10s;
    clip-path: polygon(0 0, 100% 0, 100% 45%, 0 45%);
    transform: translate(-0.025em, -0.0125em);
    opacity: 0.8;
}

.glitch span:last-child {
    animation: glitch 375ms infinite;
    animation-delay: 10s;
    clip-path: polygon(0 80%, 100% 20%, 100% 100%, 0 100%);
    transform: translate(0.0125em, 0.025em);
    opacity: 0.8;
}

@keyframes glitchVis {
    from {
        visibility: hidden;
        opacity: 0;
    }
    to {
        visibility: visible;
    }
}

@keyframes glitch {
    0% {
        visibility: visible;
        text-shadow: 
            0.05em 0 0 rgba(255, 0, 0, .75),
            -0.025em -0.05em 0 rgba(0, 255, 0, .75),
            0.025em 0.05em 0 rgba(0, 0, 255, .75); 
    }
    14% {
        text-shadow: 
            0.05em 0 0 rgba(255, 0, 0, .75),
            -0.025em -0.05em 0 rgba(0, 255, 0, .75),
            0.025em 0.05em 0 rgba(0, 0, 255, .75); 
    }
    15% {
        text-shadow: 
            -0.05em -0.025em 0 rgba(255, 0, 0, .75),
            0.025em 0.025em 0 rgba(0, 255, 0, .75),
            -0.05em -0.05em 0 rgba(0, 0, 255, .75); 
    }
    49% {
        text-shadow: 
            -0.05em -0.025em 0 rgba(255, 0, 0, .75),
            0.025em 0.025em 0 rgba(0, 255, 0, .75),
            -0.05em -0.05em 0 rgba(0, 0, 255, .75); 
    }
    50% {
        text-shadow: 
            0.025em 0.05em 0 rgba(255, 0, 0, .75),
            0.05em 0 0 rgba(0, 255, 0, .75),
            0 -0.05em 0 rgba(0, 0, 255, .75); 
    }
    99% {
        text-shadow: 
            0.025em 0.05em 0 rgba(255, 0, 0, .75),
            0.05em 0 0 rgba(0, 255, 0, .75),
            0 -0.05em 0 rgba(0, 0, 255, .75); 
    }
    100% {
        text-shadow: 
            -0.025em 0 0 rgba(255, 0, 0, .75),
            -0.025em -0.025em 0 rgba(0, 255, 0, .75),
            -0.025em -0.05em 0 rgba(0, 0, 255, .75); 
    }
} */